/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'cursor-text': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5 2a.5.5 0 01.5-.5c.862 0 1.573.287 2.06.566.174.099.321.198.44.286.119-.088.266-.187.44-.286A4.17 4.17 0 0110.5 1.5a.5.5 0 010 1c-.638 0-1.177.213-1.564.434a3.5 3.5 0 00-.436.294V7.5H9a.5.5 0 010 1h-.5v4.272c.1.08.248.187.436.294.387.221.926.434 1.564.434a.5.5 0 010 1 4.17 4.17 0 01-2.06-.566A5 5 0 018 13.65a5 5 0 01-.44.285 4.17 4.17 0 01-2.06.566.5.5 0 010-1c.638 0 1.177-.213 1.564-.434.188-.107.335-.214.436-.294V8.5H7a.5.5 0 010-1h.5V3.228a3.5 3.5 0 00-.436-.294A3.17 3.17 0 005.5 2.5.5.5 0 015 2m2.648 10.645"/>',
    },
});
